import React from 'react'
import ZoomMain from '../../component/zoom/ZoomMain'

export default function ClaimMain() {

  return (
    <div>
        <main className="">
            <section className="">
                <ZoomMain zoommainstatic={{zoommainid: `claiminput`}} />
            </section>
        </main>
    </div>
  )
}